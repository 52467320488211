<template>
     <div >

      <b-button variant="primary" @click="generateReport">{{ $t('PDFS.CERTIFICATION.generate_certificate') }}</b-button>

      <div class="p-relative">
     <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :filename="`certificado_${slugCourseName}`"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :html-2-pdf-options="{
            margin: 0,
            filename: `certificado_${slugCourseName}`,
            image: {
              type: 'jpeg',
              quality: 0.98
            },
            enableLinks: false,
            html2canvas: {
              scale: 2,
              useCORS: true,
              scrollX:0,
              scrollY:0
            },
            jsPDF: {
              unit: 'in',
              format: 'a4',
              orientation: 'landscape'
            }
          }"
        ref="html2Pdf"
        style="width:0;height:0;overflow: hidden; position: relative;"
    >
        <section slot="pdf-content" class="certification-pdf" style="height: 794px; width: 100%;">
          <section class="pdf-item" style="display: flex; flex-direction: column; height: 100%;">
          <div class="background text-center" style="display: flex; flex-direction: column; align-items: center; flex: 1; justify-content: center;">

            <img src="/media/logos/cyber_academy_logo_short.svg" alt="" style="margin-top: -150px; width: 200px;" class="mb-3">
            <span class="certificate-title mt-5">{{ $t('PDFS.CERTIFICATION.certificate') }}</span>

            <span class="given-to">{{ $t('PDFS.CERTIFICATION.given_to') }}</span>
            <span class="student-name mb-2">{{ studentName }}</span>

            <span class="course-name mt-3 mb-2 block">{{ courseName }}</span>
            <span>{{ $t('PDFS.CERTIFICATION.total_duration') }}: {{ courseDurationText }}</span>
            <span>{{ $t('PDFS.CERTIFICATION.date_finish') }}: {{ courseDateFinish }}</span>

            <span v-if="hasCourseDirector" class="block mt-5">{{ courseDirector }}</span>
            <span v-if="hasCourseDirector" class="course-director">{{ $t('PDFS.CERTIFICATION.course_director') }}</span>
          </div>
            <img class="p-absolute corner" style="top: 0;" :style="{ left: `${leftCornerSpace}px` }" src="/media/img/resources/top_left_corner.svg" alt="">
            <img class="p-absolute corner" style="top: 0;" :style="{ right: `${rightCornerSpace}px` }" src="/media/img/resources/top_right_corner.svg" alt="">
            <img class="p-absolute corner" style="bottom: 0;" :style="{ left: `${leftCornerSpace}px` }" src="/media/img/resources/bottom_left_corner.svg" alt="">
            <img class="p-absolute corner" style="bottom: 0;" :style="{ right: `${rightCornerSpace}px` }" src="/media/img/resources/bottom_right_corner.svg" alt="">
            <img class="p-absolute" style="bottom: 55px; left: 64px; width: 200px;" src="/media/logos/telefonica_tech.png" alt="">
            <img class="p-absolute" style="bottom: 55px; right: 64px; width: 200px;" src="/media/logos/a_telefonica_company.png" alt="">

          </section>
        </section>
    </vue-html2pdf>
      </div>

   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

import FormatMixin from '@/services/mixins/format.mixins'

export default {
  name: 'CertificationPdf',
  components: {
    VueHtml2pdf
  },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  mixins: [FormatMixin],
  data () {
    return {
      scrollY: 0,
      hasScroll: true
    }
  },
  methods: {
    generateReport () {
      const body = document.getElementById('content')
      this.hasScroll = this.detectOverflow(body)
      this.$refs.html2Pdf.generatePdf()
    },
    detectOverflow (el) {
      var curOverflow = el.style.overflow

      if (!curOverflow || curOverflow === 'visible') { el.style.overflow = 'hidden' }

      var isOverflowing = el.clientWidth < el.scrollWidth ||
          el.clientHeight < el.scrollHeight

      el.style.overflow = curOverflow

      return isOverflowing
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser'
    }),
    studentName () { return typeof this.course.name !== 'undefined' ? this.user.name : '' },
    courseName () { return this.course.name },
    hasCourseName () { return typeof this.course.name !== 'undefined' && this.course.name !== '' },
    slugCourseName () { return this.hasCourseName ? this.slugify(this.courseName) : '' },
    courseDurationText () { return `${this.courseDuration} ${this.courseDuration === 1 ? this.$t('GENERAL.hour') : this.$t('GENERAL.hours')}` },
    hasCourseDirector () { return typeof this.course.author !== 'undefined' && this.course.author !== '' },
    courseDirector () { return this.hasCourseDirector ? this.course.author : '' },
    courseDateFinish () {
      return typeof this.course.date_finish !== 'undefined' ? new Date(this.course.date_finish).toLocaleDateString() : ''
    },
    courseDuration () {
      if (typeof this.course.pills === 'undefined') return 0

      var durationInMinutes = this.course.pills.reduce((acc, item) => acc + item.duration, 0)
      return (durationInMinutes / 60).toFixed(2)
    },
    isMobile () {
      return new RegExp(/Mobi|Android/, 'i').test(navigator.userAgent)
    },

    leftCornerSpace () {
      // TODO At first we had issues and needed to apply logic, now we don't. Probably will delete this in the future
      if (this.isMobile) {
        return 0
      } else {
        return this.hasScroll ? 0 : 0
      }
    },
    rightCornerSpace () {
      // TODO At first we had issues and needed to apply logic, now we don't. Probably will delete this in the future
      if (this.isMobile) {
        return -1
      } else {
        return this.hasScroll ? -1 : -1
      }
    }
  },

  mounted () {
    // this.generateReport()
  }
}
</script>

<style lang="scss">
  .certification-pdf {

    .certificate-title {
      font-weight: bold;
      font-size: 4rem;
      color: #2E1A7E;
      text-transform: uppercase;
    }

    .given-to {
      font-size: 1.5rem;
    }

    .student-name {
      color: #2E1A7E;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2rem;
    }

    .course-name {
      font-size: 2.5rem;
      line-height: 2rem;
      font-weight: bold;
      color: #9C15A9;
    }

    .course-director {
      font-size: 0.9rem;
      line-height: 1rem;
      color: #9C15A9;
    }

    img.corner {
      width: 128px;
    }
  }

  .p-absolute {
    position: absolute;
  }
</style>
