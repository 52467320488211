<template>
  <div class="home">
    <loading :loading="isLoading"></loading>
    <SectionHeader
    :name="courseDetail.name"
    :description="courseDetail.description"
    :progress="0"
    has-back-button
    >
      <template v-slot:extra>
        <CertificationPdf :course="courseDetail" style="float: right;" v-if="progress === 100" />
      </template>
    </SectionHeader>

    <!-- SKELETONS LOADING -->
    <b-row class="main-container py-5 px-md-5 overflow-x-hidden" v-if="isLoading">
      <b-col class="mb-3" sm="12" md="6" lg="4" xl="3" v-for="n in Array(12)" v-bind:key="n">
        <CategoryCardSkeleton />
      </b-col>
    </b-row>
    <!-- END SKELETONS LOADING -->

    <div class="main-container py-5 px-md-5 overflow-x-hidden no-lateral-margin" v-if="hasChilds">
      <b-row v-if="!hasPills">
        <b-col class="mb-3" sm="12" md="6" lg="4" xl="3"
        v-for="content in children"
        v-bind:key="content.id">
          <CategoryCard
            :img="getUrlImg(content.image)"
            :title="content.name"
            :text="content.description"
            :progress="calculateProgress(content.score, content.total_points)"
            :to="{ name: 'objectiveDetail', params: { id: content.id } }"
          >
          </CategoryCard>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="mb-3" sm="12">
          <PillsTable :items="translatedPills" />
        </b-col>
      </b-row>
    </div>
    <NoResults class="mt-4" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryCard from '@/components/utils/cards/CategoryCard'
import CategoryCardSkeleton from '@/components/skeletons/CategoryCardSkeleton'

import SectionHeader from '@/components/SectionHeader'

import PillsTable from '@/components/tables/Pills'
import NoResults from '@/components/utils/NoResults'

import CertificationPdf from '@/components/pdfs/Certification'

import ProgressMixin from '@/services/mixins/progress.mixins'
import mapper from '@/services/mixins/mapper.mixins'
import ErrorsMixin from '@/services/mixins/errors.mixins'
import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'CourseDetail',
  mixins: [ProgressMixin, mapper, ErrorsMixin, i18nmixin],
  components: { CategoryCard, SectionHeader, PillsTable, CategoryCardSkeleton, NoResults, CertificationPdf },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions({
      getCourseDetail: 'academy/getCourseDetail'
    })
  },
  computed: {
    ...mapGetters({
      originalCourseDetail: 'academy/getCourseDetail',
      isLoading: 'academy/isLoading'
    }),
    courseDetail () { return this.translateObject(this.originalCourseDetail) },
    progress () { return this.calculateProgress(this.courseDetail.score, this.courseDetail.total_points) },
    translatedPills () { return this.translateObjectArray(this.courseDetail.pills) },
    children () { return this.translateObjectArray(this.originalChildren) },
    originalChildren () { return this.courseDetail.objectives || this.courseDetail.pills || [] },
    hasPills () { return this.courseDetail.pills && this.courseDetail.pills.length > 0 },
    hasChilds () { return this.children.length > 0 }
  },
  mounted () {
    this.getCourseDetail(this.$route.params.id)
      .catch(this.handleAxiosError)
  }
}
</script>
